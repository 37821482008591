@charset "UTF-8";
.app-wrapper[data-v-8cbd50c2] {
  overflow: hidden !important;
}
.app-container[data-v-8cbd50c2] {
  overflow-x: auto;
}
.select_box_big[data-v-8cbd50c2] {
  width: 100%;
  padding: 0 5px 5px 5px;
}
.select_box_big .el-select[data-v-8cbd50c2] {
  width: 100% !important;
}
.select_box_big .el-input[data-v-8cbd50c2] {
  width: 100% !important;
}
.padding_sty[data-v-8cbd50c2] {
  border-bottom: 1px solid #eee;
  margin: 5px;
}
.padding_sty .top_input[data-v-8cbd50c2] {
  border-bottom: none !important;
}
.top_item[data-v-8cbd50c2] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top_item input[data-v-8cbd50c2] {
  width: 100% !important;
}
.top_item .top_input[data-v-8cbd50c2] {
  flex: 1;
  height: 24px !important;
  display: inline-block;
  background-color: #fff;
  background-image: none;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  line-height: 24px;
  outline: none;
  padding: 0 6px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.top_item .el-select[data-v-8cbd50c2] {
  flex: 1;
  display: inline-block;
}
.bottom_rate[data-v-8cbd50c2] {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-bottom: 1px solid #eee;
}
.bottom_rate label[data-v-8cbd50c2] {
  flex: 1;
  font-size: 14px;
  color: #333;
  line-height: 24px;
  padding-left: 10px;
  font-weight: normal;
}
.bottom_rate input[data-v-8cbd50c2] {
  height: 24px !important;
  display: inline-block;
  flex: 1;
  width: 100px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  line-height: 24px;
  outline: none;
  padding: 0 6px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.bottom_btn[data-v-8cbd50c2] {
  width: 1200px;
  text-align: right;
  margin-top: 30px;
  margin-left: 30px;
}
.bottom_btn2[data-v-8cbd50c2] {
  width: 1200px;
  text-align: right;
  margin-top: 30px;
  margin-left: 30px;
}
.text_box li[data-v-8cbd50c2]:nth-child(3) {
  border-color: #f0ffb6;
}
.text_box li[data-v-8cbd50c2]:nth-child(6) {
  border-color: #ffedb0;
}
.text_box li[data-v-8cbd50c2]:nth-child(9) {
  border-color: #81ff89;
}
.text_box li[data-v-8cbd50c2]:nth-child(12) {
  border-color: #9dd7ff;
}
.big_gsbqw[data-v-8cbd50c2] {
  font-size: 0;
  border-top: 1px solid #eee;
  position: relative;
  height: 68px;
}
.big_gsbqw p[data-v-8cbd50c2] {
  position: absolute;
  left: 7px;
  top: 0;
  height: 68px;
  line-height: 68px !important;
  font-size: 14px;
  font-weight: normal !important;
  text-align: right !important;
  width: 100%;
}
.big_gsbqw div[data-v-8cbd50c2] {
  float: left;
  width: 7.6923076923%;
  height: 68px;
  font-size: 14px;
  vertical-align: top;
  border-left: 1px solid #eee;
}
.big_gsbqw div[data-v-8cbd50c2]:nth-child(3) {
  border-color: #FFA9A9;
}
.big_gsbqw div[data-v-8cbd50c2]:nth-child(6) {
  border-color: #FFD089;
}
.big_gsbqw div[data-v-8cbd50c2]:nth-child(9) {
  border-color: #81ff89;
}
.big_gsbqw div[data-v-8cbd50c2]:nth-child(12) {
  border-color: #9dd7ff;
}
.big_gsbqw div[data-v-8cbd50c2]:nth-child(1) {
  border-left: none;
}
.td-money[data-v-8cbd50c2] {
  width: 100%;
  text-align: center;
  position: relative;
}
.td-money input[data-v-8cbd50c2] {
  border: none !important;
  background: none !important;
  width: 100%;
  height: 68px;
  letter-spacing: 2px;
  text-align: right;
  font-size: 22px;
}
.td-money > div[data-v-8cbd50c2] {
  width: 100% !important;
  text-align: right;
  height: 100%;
  line-height: 67px;
  font-size: 14px;
}
.td-money span[data-v-8cbd50c2] {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 7px;
  top: 0;
}
.tops[data-v-8cbd50c2] {
  font-size: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tops .add[data-v-8cbd50c2] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #f15a24;
  cursor: pointer;
}
.tops .add .svg-icon[data-v-8cbd50c2] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.tops .gsbqw[data-v-8cbd50c2] {
  font-size: 0;
  border-top: 1px solid #f3f3f3;
}
.tops .gsbqw div[data-v-8cbd50c2] {
  float: left;
  width: 7.6923076923%;
  height: 32px;
  font-size: 14px;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
}
.tops .gsbqw div[data-v-8cbd50c2]:nth-child(3) {
  border-color: #FFA9A9;
}
.tops .gsbqw div[data-v-8cbd50c2]:nth-child(6) {
  border-color: #FFD089;
}
.tops .gsbqw div[data-v-8cbd50c2]:nth-child(9) {
  border-color: #81ff89;
}
.tops .gsbqw div[data-v-8cbd50c2]:nth-child(12) {
  border-color: #9dd7ff;
}
.tops .gsbqw div[data-v-8cbd50c2]:nth-child(1) {
  border-left: none;
}
.tops .box_top[data-v-8cbd50c2] {
  display: inline-flex;
  flex: 1;
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  display: inline-block;
  width: 25%;
  height: 68px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
  vertical-align: top;
  line-height: 68px;
}
.tops .box_top h6[data-v-8cbd50c2] {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}
.tops .box_top > div[data-v-8cbd50c2] {
  width: 100%;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
}
.tops .box_top .money_box_top[data-v-8cbd50c2] {
  width: 220px;
}
.tops .box_top[data-v-8cbd50c2]:first-child {
  border-left: none !important;
}
.center_title[data-v-8cbd50c2] {
  float: left;
  width: 19%;
  margin: 0 auto;
  text-align: center;
}
.center_title input[data-v-8cbd50c2] {
  font-size: 14px;
  color: #333;
  line-height: 36px;
}
.right_num[data-v-8cbd50c2] {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 14px;
  color: #333;
}
.content2[data-v-8cbd50c2] {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.bottom_input[data-v-8cbd50c2] {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}
.bottom_input .item_btn[data-v-8cbd50c2] {
  width: 46%;
  margin: 0 auto;
  margin-bottom: 15px;
  position: relative;
}
.bottom_input .item_btn h6[data-v-8cbd50c2] {
  float: left;
  width: 80px;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
  text-align: right;
}
.bottom_input .item_btn p[data-v-8cbd50c2] {
  float: left;
  line-height: 36px;
  color: #333;
  font-size: 15px;
  font-weight: normal;
}
.bottom_input .item_btn input[data-v-8cbd50c2] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.bottom_input .left_box[data-v-8cbd50c2] {
  width: 46%;
}
.bottom_input .right_box[data-v-8cbd50c2] {
  width: 46%;
}
.text_big[data-v-8cbd50c2] {
  position: relative;
  height: 68px;
}
.text_big li[data-v-8cbd50c2] {
  float: left;
  width: 7.6923076923%;
  height: 68px;
  line-height: 68px;
  text-align: center;
  font-size: 14px;
  color: #333;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
  z-index: 8;
  position: relative;
  font-weight: bold;
}
.text_big li[data-v-8cbd50c2]:nth-child(1) {
  border-left: none;
}
.text_big input[data-v-8cbd50c2] {
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 0 auto;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom[data-v-8cbd50c2] {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 68px;
  position: relative;
  display: flex;
}
.bottom p[data-v-8cbd50c2] {
  font-size: 14px;
  text-align: center;
  line-height: 56px;
  font-weight: 600;
}
.bottom .add[data-v-8cbd50c2] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.bottom .add .svg-icon[data-v-8cbd50c2] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.bottom .close[data-v-8cbd50c2] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #F56C6C;
  cursor: pointer;
}
.bottom .close .svg-icon[data-v-8cbd50c2] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.bottom[data-v-8cbd50c2]:last-child {
  border-bottom: none;
}
.common_box[data-v-8cbd50c2] {
  width: 25%;
  flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box[data-v-8cbd50c2]:first-child {
  border-left: 0;
}
.infarm_box[data-v-8cbd50c2] {
  width: 100%;
  height: 68px;
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.big_box3[data-v-8cbd50c2] {
  width: 1200px;
  border: 1px solid #ddd;
  padding: 20px 60px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 30px;
  min-height: 500px;
  position: relative;
}
.big_box3 .left_sh[data-v-8cbd50c2] {
  position: absolute;
  left: 60px;
  top: 20px;
}
.big_box3 .left_sh img[data-v-8cbd50c2] {
  width: 100px;
  z-index: 99999999999999;
}
.big_box3 .left_sh .status_true[data-v-8cbd50c2] {
  padding: 6px 20px;
  border: 2px solid #67c23a;
  font-size: 14px;
  color: #67c23a;
}
.big_box3 .left_sh .status_false[data-v-8cbd50c2] {
  padding: 6px 20px;
  border: 2px solid #f56c6c;
  font-size: 14px;
  color: #f56c6c;
}
.big_box3 .moban[data-v-8cbd50c2] {
  position: absolute;
  top: 20px;
  right: 50px;
}
.big_box3 .arrow_btn[data-v-8cbd50c2] {
  text-align: center;
}
.big_box3 .arrow_btn .two_btn[data-v-8cbd50c2] {
  font-size: 20px;
  color: #f15a24;
  line-height: 60px;
  background: #eee;
  cursor: pointer;
}
.big_box3 .arrow_btn .arrow_left[data-v-8cbd50c2] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box3 .arrow_btn .arrow_right[data-v-8cbd50c2] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box3 .bottom_time[data-v-8cbd50c2] {
  float: left;
  width: 40%;
}
.big_box3 .top_input2[data-v-8cbd50c2] {
  width: 50%;
}
.big_box3 .top_input2 h6[data-v-8cbd50c2] {
  display: inline-block;
  width: 40px;
  font-size: 14px;
  font-weight: normal;
}
.big_box3 .top_input3[data-v-8cbd50c2] {
  width: 30%;
}
.big_box3 .top_input3 h6[data-v-8cbd50c2] {
  float: left;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 26px;
  margin-right: 10px;
}
.big_box3 .top_input3 span[data-v-8cbd50c2] {
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 26px;
  margin-left: 10px;
}
.big_box3 .top_input3 input[data-v-8cbd50c2] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.top_title[data-v-8cbd50c2] {
  width: 100%;
  margin: 25px auto;
  position: relative;
}
.top_title h5[data-v-8cbd50c2] {
  font-size: 24px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 25px;
}
.top_title .tags_title[data-v-8cbd50c2] {
  font-size: 15px !important;
  color: #666;
  margin-left: 50px;
  position: absolute;
  right: 30%;
  top: 10%;
}
.top_title .radio_box[data-v-8cbd50c2] {
  position: absolute;
  top: 6px;
  left: 0;
}
.boss[data-v-8cbd50c2] {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}
.boss .iconfont icon-cuowu[data-v-8cbd50c2] {
  position: absolute;
  right: 2px;
  top: 2px;
  color: #f56c6c;
}
.boss .boss-list[data-v-8cbd50c2] {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.select_box[data-v-8cbd50c2] {
  position: relative;
}
.select_box .yuer[data-v-8cbd50c2] {
  position: absolute;
  bottom: 0;
  color: #777;
  font-size: 10px;
}
.select_box .yuer .money[data-v-8cbd50c2] {
  color: #409eff !important;
}
.select_box .select_subject[data-v-8cbd50c2] {
  width: 100%;
  height: 68px;
  border: none;
}
.select_box .uls[data-v-8cbd50c2] {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9999999999;
  display: block;
  height: 230px;
  background: #efefef;
  width: 450px;
  overflow: auto;
}
.select_box .uls ul[data-v-8cbd50c2] {
  position: absolute;
  left: 0;
}
.select_box .uls ul li[data-v-8cbd50c2] {
  line-height: 25px;
  cursor: pointer;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
}
.cur[data-v-8cbd50c2] {
  background: #f0ffb6;
}
.xinzheng[data-v-8cbd50c2] {
  width: 450px;
  height: 30px;
  line-height: 30px;
  background: #f15a24;
  color: #fff;
  text-align: center;
  border: 1px solid #f15a24;
  position: absolute;
  top: 278px;
  left: 0;
  z-index: 99999999999;
  cursor: pointer;
}
.button_bg .item[data-v-8cbd50c2] {
  margin-bottom: 10px;
}
.inventory[data-v-8cbd50c2] {
  margin-left: 30px;
}
.inventory table[data-v-8cbd50c2] {
  border-color: 1px #f15a24 solid;
}
.inventory table tr[data-v-8cbd50c2] {
  line-height: 30px;
}
.inventory table tr td[data-v-8cbd50c2] {
  width: 100px;
  text-align: center;
}
.yuer[data-v-8cbd50c2] {
  position: absolute;
  bottom: 0;
  color: #777;
  font-size: 10px;
  z-index: 1000;
}
.yuer .money[data-v-8cbd50c2] {
  color: #409eff !important;
}
.yuer[data-v-8cbd50c2]:hover {
  color: #1114da !important;
  cursor: pointer;
}